$navHeight: calc(100vh - #{rem($barHeight)});

@mixin pageNavi($class: "#navigation") {
  #{$class} {
    position: fixed;
    max-width: 100%;
    right: 0;
    @content;
  }
}
@mixin ul {
  ul {
    width: 100%;
    @content;
  }
}
@mixin navButtons {
  a,
  &:not(.navBack) > span,
  &.navBack,
  &.hasSub > span {
    // @extend %animatedTransform;
    display: block;
    cursor: pointer;
    @content;
  }
  a,
  &.hasSub > span {
    &.active {
      @extend %buttonActive;
    }
  }
  &:hover {
    &.navBack,
    > a,
    &:not(.navBack) > span {
      @extend %buttonHover;
    }
  }
}
@mixin hasSub($class: ".hasSub") {
  &#{$class} {
    > span,
    > a {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @content;

      &:after {
        @extend %nextIcon;
        margin-left: 0.2em;
        display: inline-block;
        font-size: rem(18px);
        font-weight: 600;
      }
    }
    &.active {
      > a,
      > span {
        @extend %buttonActive;
      }
    }
    @content;
  }
}
@mixin sub($class: ".sub") {
  #{$class} {
    // @extend %animatedTransform;
    position: absolute;
    left: 0;
    top: 0;
    @content;

    @include breakpoint($break, max) {
      transform: translateX(100%);
    }
    @include breakpoint($break) {
      opacity: 0;
      visibility: hidden;
    }
    &.current {
      @include breakpoint($break, max) {
        transform: translateX(0%);
      }
      @include breakpoint($break) {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
@mixin navBack {
  &.navBack {
    // @extend %animatedTransform;
    position: relative;
    @content;

    &:before {
      @extend %prevIcon;
      margin-right: 1em;
      display: inline-block;
    }
  }
}

@include breakpoint($break, max) {
  #navigation {
    min-width: 75%;
    overflow: hidden;

    @if $barPos == "top" {
      top: rem($barHeight);
      bottom: 0;
    } @else if $barPos == "bottom" {
      top: 0;
      bottom: rem($barHeight);
    }
    &.firstLevel {
      nav {
        height: $navHeight;
        overflow: hidden;
        overflow-y: auto;
      }
    }
    nav {

      ul {
        
        &.navi {
          li {
            &.hasSub {
              .sub {
                height: $navHeight;
                
                &.open {
                  -webkit-overflow-scrolling: touch;
                  overflow: hidden;
                  overflow-y: auto;
                }
                &:before {
                  content: attr(data-parent-name);
                  display:block;
                }
              }
            }
          }
        }
      }
    }
  }
  html.hiddenScroll {
    overflow: hidden;
  }
}

@mixin desktopVersionFunctionality {
  #navigation {
    top: 0;
    width: 100%;

    nav {
      ul {
        &.navi {
          display: flex;
          justify-content: space-between;

          li {
            a,
            &:not(.navBack) > span,
            .sub:before,
            .navBack {
              white-space: nowrap;
            }
            &.hasSub {
              position: relative;
              body.cmsBackend & {
                &:hover {
                  > .sub {
                    opacity:1;
                    visibility: visible;
                  }
                }
              }
              .sub {
                top: 100%;
                width: auto;

                .sub {
                  left: 100%;
                  top: 0;
                }
              }
            }
            &.navBack {

              &:before {
                content: $closeIcon;
                text-align: center;
                width: 100%;
              }
              span {
                display: none;
              }
            }
          }
        }
      }
    }

    &.hoverNavigation nav ul.navi li.navBack {
      display:none;
    }
  }
}

#quickbar {
  position: fixed;
  left: 0;
  width: 100%;

  @include breakpoint($break, max) {
    height: rem($barHeight);
  }

  @if $barPos == "top" {
    top: 0;

    @if $barHide {
      &.hide {
        transform: translateY(-100%);
      }
    }
  } @else if $barPos == "bottom" {
    bottom: 0;

    @if $barHide {
      &.hide {
        transform: translateY(100%);
      }
    }
  }

  label[for="naviToggled"] {
    display: block;
    cursor: pointer;
    width: rem($barHeight * 0.4);
    height: rem($barHeight * 0.4);
    position: relative;
    overflow: hidden;

    &:after {
      // @extend %animatedTransform;
      content: "";
      position: fixed;
      left: 0;
      width: 200vw;
      height: 200vh;
      display: block;
      background-color: $dark;
      opacity: 0.9;
      z-index: -1;

      @if $barPos == "top" {
        top: rem($barHeight);
      } @else if $barPos == "bottom" {
        bottom: rem($barHeight);
      }
    }

    span {
      transition: 0.4s linear;
      text-indent: -9999px;
      width: 0;
      top: 50%;
      transform: translate(-50%, -50%);

      &,
      &:before,
      &:after {
        content: "";
        position: absolute;
        display: block;
        background: $barColor;
        height: 2px;
        left: 50%;
        transform-origin: center;
      }
      &:before,
      &:after {
        top: calc(50% + 0rem);
        width: rem($barHeight * 0.4);
        transition: top 0.3s ease-in-out, transform 0.3s 0.2s linear;
      }
      &:before {
        transform: translate(-50%, -50%) rotate(45deg);
      }
      &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }
}

#naviToggled {
  display: none;

  &:not(:checked) {
    ~ * #navigation,
    ~ #navigation {
      @include breakpoint($break, max) {
        transform: translateX(100%);
      }
    }
    ~ #quickbar {
      label[for="naviToggled"] {
        &:after {
          opacity: 0;
          visibility: hidden;
        }
        span {
          width: rem($barHeight * 0.4);

          &:before,
          &:after {
            transform: translate(-50%, -50%);
            transition: top 0.3s 0.2s ease-in-out, transform 0.3s linear;
          }
          &:before {
            top: calc(50% + -#{rem(($barHeight * 0.4) * 0.25)});
          }
          &:after {
            top: calc(50% + #{rem(($barHeight * 0.4) * 0.25)});
          }
        }
      }
    }
  }
}
