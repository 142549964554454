// GLOBAL SETTINGS
//////////////////////////////////////////////

$break: giant; // Breakpoint – switch to Desktop version

// NAVIGATION
//////////////////////////////////////////////

$navBg: $primary; // Navigation Background
$navColor: $light; // Navigation Link Color

@mixin navButton {
	// Default Link Styles
	padding: 1em rem($baseGap);
	text-decoration: none;
	color: $navColor;
	font-weight: 500;
	letter-spacing: 2px;
	@include breakpoint($break){
		padding: 1em 0;
		color:$dark;
	}
	@include breakpoint(huge){
		font-size: rem(20px);
	}
}
%buttonHover {
	// Link Hover
	color: $redmedium;
	@include breakpoint($break){
		color:$primary;
	}
}
%buttonActive {
	// Link Active
	color: $redmedium;
	@include breakpoint($break){
		color:$primary;
	}
}
%prevIcon {
	// Icon to previus Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);
	transform: rotate(180deg);
	color: $redmedium;

	// @include breakpoint($break) {
	// 	transform:rotate(90deg);
	// }
}
%nextIcon {
	// Icon to next Submenu
	font-family: $iconFont;
	content: map-get($iconMap, angle-right);
	color: $redmedium;

	@include breakpoint($break) {
		transform:rotate(90deg);
	}
}
$closeIcon: map-get($iconMap, times); // Icon to close Submenu

// Quickbar
//////////////////////////////////////////////

$barHeight: 50px; // height (in px)
$barPos: top; // position (top, bottom)
$barHide: true; // if bar hides on scroll (true, false)

$barBg: $dark; // background-color
$barColor: $light; // children colors – texts and burgerbutton

//////////////////////////////////////////////
// NAVIGATION FUNCTIONALITY
//////////////////////////////////////////////

@import "../modules/ruffnav";

// Navigation Styles
////
@include pageNavi {
	background-color: $navBg;
	z-index: 1001;

	img{
		display: none;
	}
	.right{
		.oben{
			display: none;
		}
	}

	nav {
		@include ul {
			&.navi {
				li {

					&.regelungssysteme{
						margin-bottom: rem(300px);
						@include breakpoint($break){
							margin-bottom: 0;
						}
					}
					@include navButtons {
						@include navButton;
					}
					span{
						transition: all 300ms;
					}
					@include hasSub {
						@include sub {
							background: $navBg;
							
							a{
								border-bottom: 1px dotted $iconred;
								padding: rem(12px) $baseGap;
							}

							&:before {
								padding: 1em rem($baseGap);
								background-color: darken($navBg, 6%);
								color: $light;
							}
						}
					}
					@include navBack {
						font-size: rem(15px);
						color: $iconred;
					}
				}
			}
		}
	}
}


// Navigation Desktop Styles
////
@include breakpoint($break) {
	@include desktopVersionFunctionality;

	

	#navigation {
		top: inherit;
    	position: absolute;
		transition: all 600ms;
		background:$light;
		position: fixed;
		border-bottom: 1px solid $border;

		.flexer{
			display: flex;
			justify-content: space-between;
			padding: 20px 0;
			img{
				display: block;
			}
			.right{
				padding-top: 12px;
				.oben{
					justify-content: flex-end;
					display: flex;
					margin-bottom: 15px;
					.mailer{
						margin-left: 30px;
					}
				}
			}
		}
	
		.branding {
			display: none;
			@include breakpoint(small){
				display: block;
				max-width: 370px;
				width: 100%;
				height: auto;
				margin: 0 auto;
				position: relative;
				z-index: 1;
			}
		}

		// &.fix{
		// 	position: fixed;
		// 	top: 0;
		// 	transition: all 600ms;
		// }
		nav {
			ul {
				&.navi {
					li {
						margin-left: 28px;
						@include breakpoint(full){
							margin-left: 50px;
						}
						a{
							padding: 0;
						}
						span{
							padding: 0;
						}
						&.hasSub {
							span{
								padding: 0;
							}
							.sub {
								li{
									margin-left: 0;
									a{
										color: $light;
										&:hover, &.active{
											color: $redmedium;
										}
									}
								}
							}
						}
						&.navBack {
						}
					}
				}
			}
		}
	}
}

// Quickbar Styles
////
#quickbar {
	background-color: $barBg;
	color: $barColor;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: rem($baseGap);
	z-index: 1000;

	@include breakpoint($break) {
		display: none;
	}
}

//////////////////////////////////////////////
// naviAdd
//////////////////////////////////////////////
.naviAdd {
	text-align: center;
	background: $primary;
	
	@include breakpoint(small){
		display: flex;
		justify-content: center;
		text-align: left;
	}

	a{
		color: $light;
		font-weight: 500;
		&:hover, &.active{
			color: $iconred;
		}
	}

	li {
		padding: 12px 0;
		border-bottom: 1px solid $light;
		&:last-child{
			border-bottom: 0;
		}
		@include breakpoint(small){
			border-bottom: none;
			margin-right: 1.5rem;
			padding: 18px 0;
		}
		a{
			color: $light;
			display: block;
			&:hover, &.active{
				color: $iconred;
			}
		}
	}
}
.naviAddDesktop{
	display: none;
	@include breakpoint($break){
		display: block;
		background: $primary;
		column-count: 2;
		padding: rem(40px) rem(60px);
		a{
			color: $light;
			font-weight: 500;
			&:hover, &.active{
				color: $iconred;
			}
		}
		.hasSub{
			.sub{
				li{
					a{
						font-weight: 300;
						&:before{
							font-family: $iconFont;
							content: map-get($iconMap, angle-right);
							color: $redmedium;
							font-size: rem(15px);
							margin-right: rem(7px);
						}
					}
				}
			}
		}
	}
	@include breakpoint(giant){
		padding: rem(77px) rem(120px);
	}
}