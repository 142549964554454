
// HEADER
//////////////////////////////

#header {
	position: relative;
	width: 100%;
	margin-bottom: 3rem;
	margin-top: rem(50px);

	@include breakpoint(large) {
		padding: 0;
		margin-top: 0;
		margin-bottom: rem(210px);
	}

	@include breakpoint(giant){
		padding-top: 145px;
    	margin-top: 0;
	}

	.col{
		padding: 0 !important;
		@include breakpoint(large){
			padding-left: 1rem !important;
   		 	padding-right: 1rem !important;
		}
	}

	.swiper-container{
		
		.swiper-slide{
			height: 400px;
			background-repeat: no-repeat;
			background-size: cover;
			display: flex;
			align-items: center;
			justify-content: center;

			@include breakpoint(medium){
				height: 500px;
			}
			@include breakpoint(large){
				height: 600px;
			}
			@include breakpoint(giant){
				height: 630px;
			}
			&.slide1{
				@include responsiveImage('layout/handwerk.jpg', (tiny, small, medium, large, giant), false);
				background-position: center;
				.branding{
					
					img{
						background: $light;
						padding: 15px;
						max-width:220px;
						display: block;
						margin: 0 auto 20px auto;
					}
					@include breakpoint(giant) {
						display: none;
					}
				}
			}
			&.slide2{
				@include responsiveImage('layout/slides/heizung.jpg', (tiny, small, medium, large, giant), true);
			}
			&.slide3{
				@include responsiveImage('layout/slides/sanitaer.jpg', (tiny, small, medium, large, giant), true);
			}
			&.slide4{
				@include responsiveImage('layout/slides/baeder.jpg', (tiny, small, medium, large, giant), true);
				background-position: center;
			}
			.claimer{
				color: $light;
				font-size: rem(22px);
				line-height: rem(34px);
				padding:18px;
				text-align: center;
				@include breakpoint(medium){
					font-size: rem(28px);
					line-height: rem(65px);
				}
				@include breakpoint(large){
					font-size: rem(34px);
					line-height: rem(65px);
				}
				span{
					font-weight: 500;
					display: block;
					font-size: rem(28px);
					@include breakpoint(medium){
						font-size: rem(46px);
					}
					@include breakpoint(large){
						font-size: rem(54px);
					}
				}
				&.leistungsClaim{
					font-weight: 500;
					border: 5px solid white;
					padding: 80px 40px;	
					@include breakpoint(small){
						padding: 60px 110px;	
					}
					@include breakpoint(medium){
						padding: 100px 150px;	
					}
					@include breakpoint(large){
						padding: 100px 200px;	
					}
					
					span{
						line-height: 10px!important;
						font-size: rem(28px);
						@include breakpoint(small){
							font-size: rem(40px);
						}
						@include breakpoint(medium){
							font-size: rem(50px);
						}
						@include breakpoint(large){
							font-size: rem(70px);
						}
					}
				}
			}
		}
	}

	

	picture {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 0;
		width: 100%;
		height: auto;
	}

	.claim{
		margin-top: rem(30px);
		@include breakpoint(medium){
			font-size: rem(22px);
			line-height: rem(40px);
			margin-top: rem(35px);
		}
		@include breakpoint(huge){
			font-size: rem(26px);
			line-height: rem(50px);
			margin-top: rem(50px);
		}
		span{
			color: $primary;
			font-weight: 500;
			display: block;
			font-size: rem(24px);
			@include breakpoint(medium){
				font-size: rem(36px);
			}
			@include breakpoint(huge){
				font-size: rem(45px);
			}
		}
	}

	.line{
		height: 1px;
		background: $border;
		display: block;
		width: 50%;
    	margin: rem(30px) auto rem(30px) auto;
    	@include breakpoint(medium){
    		margin: rem(30px) auto rem(35px) auto;
    	}
    	@include breakpoint(large){
    		width: rem(360px);
    		margin: rem(65px) auto rem(65px) auto;
    	}
	}

	
	.contact{
		.btn{
			display: block;
			margin-bottom: 5px;
			font-weight: 300;
			@include breakpoint(small){
				display: inline-block;
				margin-right: 1rem;
			}
			@include breakpoint(large){
				font-size: rem(16px);
			}
			@include breakpoint(giant){
				font-size: rem(18px);

			}
			a{
				color:$light;	
			}
			
			&:last-child{
				margin-right:0;
			}
			&.phoner{
				&:hover{
					background: $primary;
					cursor: inherit;
					a{
						cursor: inherit;
					}
				}
			}
			&:hover{
				&.mailer{
					&:before{
						color: $medium !important;
						transition: all 300ms;
					}
				}
			}
			&.phoner, &.mailer{
				&:before{
					color: $iconred !important;
					transition: all 300ms;
				}
			}
			
		}
		.opening{
			margin-top: rem(20px);
			margin-bottom: rem(25px);
			@include breakpoint(medium){
				margin-bottom: rem(45px);
			}
			@include breakpoint(large){
				margin-bottom: 0;
			}
			.trenner{
				display: none;
				@include breakpoint(small){
					display: inline-block;
					margin: 0 20px;
					color: $medium;
				}
				
			}
			.clock2{
				display: block;
				@include breakpoint(small){
					display: inline-block;
				}
			}
		}
	}
}

// MAIN CONTENT
//////////////////////////////

main {
	display: block;
	overflow: hidden;

	p,
	ol,
	ul,
	dl,
	table {
		@extend .marginBottom;
	}

	ul:not(.unstyledList),
	dl:not(.unstyledList),
	ol:not(.unstyledList) {
		@extend .styledList;
	}

	img {
		margin-bottom:rem($baseGap*2);
	}

	.videoContainer{
		margin-bottom: 2rem;
		@include breakpoint(medium){
			margin-bottom: 4rem;
		}
		@include breakpoint(large){
			margin-bottom: 0;
		}
	}

	.newsTeaser{
		.beforer{
			.darkBg{
				background: $flaechedark;
				display: block;
				padding: rem(115px) rem(50px) rem(50px) rem(50px);
				color: $light;
				position: relative;
				@include breakpoint(medium){
					padding: rem(50px) rem(50px) rem(50px) calc(5rem + 50px);
				}
				@include breakpoint(large){
					padding: rem(100px) rem(70px) rem(100px) calc(5rem + 70px);
				}
				p{
					margin-bottom: 0;
					&.headliner{
						font-weight: 500;
						font-size: rem(22px);
						margin-bottom: .5rem;
					}
				}

				.newsBefore {
					width: 100%;
					text-align: center;
    				padding: 20px 0;
					background-color: $primary;
					top: 0;
					position: absolute;
					left: 0;
					

					@include breakpoint(medium){
						display: flex;
						justify-content: center;
						align-items: center;
						bottom: 0;
						width: 5rem;
						padding: 0;
					}

					span {
						transform: rotate(-90deg);
						font-weight: 500;
						letter-spacing: 2px;
						font-size: rem(24px);
					}
				}
			}
		}
	}
	.teaserSection{
		@include responsiveImage('index/rainBg.jpg', (tiny, small, medium, large, giant), true);

		&.lazyLoaded {
			background-size:cover
		}
		padding: rem(60px) 0;
		@include breakpoint(medium){
			padding: rem(100px) 0;
		}
		@include breakpoint(large){
			padding: rem(160px) 0;
		}
		.teaserer{
			.teaserBg{
				&.teaser1{
					@include responsiveImage('index/teaser/heizungTeaser.jpg', (small, medium, large, giant), true);

					&.lazyLoaded {
						background-size:cover
					}
				}
				&.teaser2{
					@include responsiveImage('index/teaser/baederTeaser.jpg', (small, medium, large, giant), true);

					&.lazyLoaded {
						background-size:cover
					}
				}
				&.teaser3{
					@include responsiveImage('index/teaser/sanitaerTeaser.jpg', (small, medium, large, giant), true);

					&.lazyLoaded {
						background-size:cover
					}
				}
				display: block;
				padding: rem(130px) rem(50px) rem(130px) calc(5rem + 50px);
				color: $light;
				position: relative;
				margin-bottom: rem(20px);
				&.teaser3{
					margin-bottom: 0;
				}
				@include breakpoint(tiny){
					padding: rem(170px) rem(50px) rem(170px) calc(5rem + 50px);
				}
				@include breakpoint(large){
					padding: rem(150px) rem(70px) rem(150px) calc(5rem + 70px);
				}

				&:hover{
					.teaserBefore{
						width:100%;
						transition: all 400ms;
						span{
							transform: rotate(0deg);
							transition: all 400ms;
						}
					}
				}
				p{
					margin-bottom: 0;
					&.headliner{
						font-weight: 500;
						font-size: rem(22px);
						margin-bottom: .5rem;
					}
				}

				.teaserBefore {
					width: 100%;
					text-align: center;
    				padding: 20px 0;
					background-color: $primary;
					top: 0;
					position: absolute;
					left: 0;
					transition: all 400ms;
					

					@include breakpoint(medium){
						display: flex;
						justify-content: center;
						align-items: center;
						bottom: 0;
						width: 5rem;
						padding: 0;
					}

					span {
						transform: rotate(-90deg);
						transition: all 400ms;
						font-weight: 500;
						letter-spacing: 2px;
						font-size: rem(24px);
					}
				}
			}
		}
	}
	.secondBox{
		@include breakpoint(giant){
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		.lefter{
			    display: inline-block;
			@include breakpoint(large){
				display: flex;
	    		align-items: baseline;
				p{
					margin-bottom: 0;
				}
				.col{
					padding-right: 10%;
					padding-left: 0;
				}
			}
			
		}
		
		.third{
			@include responsiveImage('index/werkzeug.jpg', (tiny, small, medium, large, giant), true);
			background-position: top;
			&.lazyLoaded {
				background-size:cover
			}
			height: 14rem;
			@include breakpoint(small){
				background-position: center;
			}
			@include breakpoint(medium){
				height: 18rem;
			}
			@include breakpoint(large){
				padding:  rem(400px) rem(150px);
				background-position: center;
			}
			@include breakpoint(full){
				padding: rem(400px) rem(300px);
			}
		}
	}
	.darkBox{
		background: $flaechedark;
		color: $light;
		padding: rem(30px);
		font-weight: 500;
		display: flex;
    	flex-direction: column;
    	letter-spacing: 2px;
    	line-height: 40px;
    	@include breakpoint(large){
    		align-items: center;
    		justify-content: space-evenly;
    		flex-direction: inherit;
    	}
    	@include breakpoint(giant){
    		font-size: rem(18px);
    	}
    	@include breakpoint(huge){
    		padding: rem(30px) rem(150px);
    	}
		span{
			@include breakpoint(large){
				margin-right: rem(50px);
				&:last-child{
					margin-right: 0;
				}
			}
			@include breakpoint(huge){
				margin-right: rem(90px);
				&:last-child{
					margin-right: 0;
				}
			}
		}
	}
	.secondTeaser{
		@include responsiveImage('index/rainBg.jpg', (tiny, small, medium, large, giant), true);

		&.lazyLoaded {
			background-size:cover
		}
		padding: rem(60px) 0;
		@include breakpoint(medium){
			padding: rem(100px) 0;
		}
		@include breakpoint(large){
			padding: rem(160px) 0;
		}
		.secondTeaserer{
			margin-top: rem(20px);
			width: 100%;
			@include breakpoint(large){
				margin-top: rem(50px);
			}
		}
		.teaserer{
			
			.teaserBg{
				&.teaser1{
					@include responsiveImage('index/sonstigeteaser/plan.jpg', (tiny, medium, large), true);

					&.lazyLoaded {
						background-size:cover
					}
				}
				&.teaser2{
					@include responsiveImage('index/sonstigeteaser/frieren.jpg', (tiny, medium, large), true);
					background-position: 0 40px;
					margin-bottom: 1.5rem;

					@include breakpoint(medium){
						background-position: center;
					}

					@include breakpoint(large){
						margin-bottom: 0;
					}

					&.lazyLoaded {
						background-size:cover
					}
				}
				
				display: block;
				padding: rem(130px) rem(50px) rem(130px) calc(5rem + 50px);
				color: $light;
				position: relative;
				@include breakpoint(tiny){
					padding: rem(170px) rem(50px) rem(170px) calc(5rem + 50px);
				}

				@include breakpoint(large){
					padding: rem(180px) rem(70px) rem(180px) calc(5rem + 70px);
				}

				@include breakpoint(giant){
					padding: rem(220px) rem(70px) rem(220px) calc(5rem + 70px);
				}

				&:hover{
					.newsBefore{
						width:100%;
						transition: all 400ms;
						span{
							transform: rotate(0deg);
							transition: all 400ms;
						}
					}
				}
				p{
					margin-bottom: 0;
					&.headliner{
						font-weight: 500;
						font-size: rem(22px);
						margin-bottom: .5rem;
					}
				}

				.newsBefore {
					width: 100%;
					text-align: center;
    				padding: 20px 0;
					background-color: $primary;
					top: 0;
					position: absolute;
					left: 0;
					transition: all 400ms;
					

					@include breakpoint(medium){
						display: flex;
						justify-content: center;
						align-items: center;
						bottom: 0;
						width: 5rem;
						padding: 0;
					}

					span {
						transform: rotate(-90deg);
						transition: all 400ms;
						font-weight: 500;
						letter-spacing: 2px;
						font-size: rem(24px);
						@include breakpoint(medium){
							position: absolute;
    						width: 330%;
						}
					}
				}
			}
		}
	}
	.cta{
		background: $primary;
		color: $light;
		padding:2rem 0;
		text-align: center;
		@include breakpoint(large){
			text-align: left;
		}
		a.btn{
			background: $light;
			color: $primary;
			&:hover{
				background: $dark;
				color: $light;
			}
		}
		.flexer{
			@include breakpoint(large){
				justify-content: space-between;
				display: flex;
				align-items: center;
			}
			
			p{
				font-weight: 500;
				font-size: rem(22px);
				@include breakpoint(large){
					margin-bottom: 0;
					margin-right: 2rem;
				}
				@include breakpoint(full){
					font-size: rem(25px);
				}
			}
		}
	}
}

#privacyPolicy
{
	a
	{
		display: inline-block; 
		word-break: break-all;
	}
}

// FOOTER
//////////////////////////////

#footer {

	text-align: center;
    .first{
    	@include breakpoint(small){
    		display: block;
    	}
    }
    .large-5{
		@include breakpoint(large){
			font-size: 17px;
		}
		@include breakpoint(giant){
			    font-size: 18px;
		}
    }
	.contact{
		margin: 1.5rem 0;


		@include breakpoint(small){
			display: inline-flex;
		}
		.mailer{
			@include breakpoint(small){
				margin-left: 2rem;
			}
		}
		a.email{

			@include breakpoint(medium){
				color: $primary;
				&:hover, &.active{
					color: $secondary;
				}
			}
		}
	}
    

	@include breakpoint($break){
		background: $light;
		text-align: left;
		color: $dark;
		margin-bottom: rem(160px);
	}

	.justMobile{
		margin-top: 2rem;
		background: $primary;
		@include breakpoint($break){
			display: none;
		}
	}
	.trenner{
		display: none;
		@include breakpoint(small){
			display: inline-block;
			margin: 0 20px;
			color: $medium;
		}
		@include breakpoint(large){
			display: none;
		}
		@include breakpoint(giant){
			display: inline-block;
		}
	}
	.clock2{
		display: block;
		@include breakpoint(small){
			display: inline-block;
		}
		@include breakpoint(large){
			display: block;
			margin-left: 22px;
		}
		@include breakpoint(giant){
			display: inline-block;
			margin-left: 0;
		}
	}
}

.phoner{
	&:before{
		font-family: $iconFont;
		content: map-get($iconMap, phone);
		font-size: rem(16px);
		color: $medium;
		margin-right: 5px;
	}
}
.mailer{
	&:before{
		font-family: $iconFont;
		content: map-get($iconMap, mail);
		font-size: rem(16px);
		color: $medium;
		margin-right: 5px;
	}
}
.opening{
	&:before{
		font-family: $iconFont;
		content: map-get($iconMap, clock);
		font-size: rem(16px);
		color: $medium;
		margin-right: 5px;
	}
}
.checker{
	&:before{
		font-family: $iconFont;
		content: map-get($iconMap, check);
		font-size: rem(18px);
		color: $medium;
		margin-right: 10px;
	}
}

body:not(.index){
	#header{
		margin-bottom: rem(50px);
		height: 300px;
		width: 100%;
		padding-top: 50px;
		@include responsiveImage('layout/handwerk.jpg', (tiny, small, medium, large, giant), false);
		

		&.lazyLoaded {
			background-size:cover
		}
		@include breakpoint(medium){
			margin-bottom: rem(100px);
		}
		@include breakpoint(large){
				height: 350px;
		}
		@include breakpoint(giant){
			height: 500px;
			background-position: 0 20px;
		}
		@include breakpoint(full){
			background-position: 0 -110px;
		}
		

		.swiper-container{
			display: none;
		}
		
		.claim, .line, .contact{
			display: none !important;
		}
		.headerBg{
			height: 8rem;
			margin-top: 1.5rem;
			@include breakpoint(small){
				height: 11rem;
			}
			@include breakpoint(large){
				padding: rem(120px) 0;
				margin-top: 0;
			}
		}
	}	
}

.collapser{
	width:100%;
	font-size: rem(18px);
	padding: 1rem 3.5rem 1rem 1.2rem;
	@include breakpoint(small){
		font-size: rem($h3Size);
		padding: 1rem 1.5rem;
	}
}
.collapsBox{
    width: 100%;
	.inhalt{
		@include breakpoint(large){
			display: flex;
			align-items: center;
			margin-bottom: 1.5rem;
		}
	}
}

.borderPartner{
	border: 1px solid $border;
	&.nameText{
		text-align: center;
		padding: $baseGap;
	}
}

body.cmsBackend{
	.h3{
		color: $dark;
	}
	.collapsBox{
		.inhalt{
			display: block;
		}
	}
	.boxedPartner img{
		height: auto !important;
	}
}
body.jobs{
	.h6{
		margin-bottom: 0;
	}
}

body.cmsBackend{
	.teaserSection, .secondTeaser, #footer{
		display: none;
	}
}

body.partner{
	.boxedPartner{
		border: 1px solid $border;
		margin-bottom: 1.5rem;
		
		img{
			object-fit: contain;
			height: 250px;
			padding: 1rem;
			margin-bottom: 0;
		}
	}
	.modalGallery img {
		box-shadow: none !important;
	}
}

.picContainer{
	margin-top:20px;
	@include breakpoint(medium){
		margin-top:40px;
	}
	@include breakpoint(large){
		margin-top:65px;
	}
}

.backgrounderTease{
	height:300px;
	&.tease1{
		@include responsiveImage('index/teaser/baederTeaser.jpg', (large), true);
	}
	&.tease2{
		@include responsiveImage('layout/barrierefrei/barrierefrei4.jpg', (large), true);
		background-position: bottom;
	}
	&.tease3{
		@include responsiveImage('layout/sanitaer/sanitaer5.jpg', (large), true);
	}

		&.lazyLoaded {
			background-size:cover
		}
}
.teaseSection{
	.btn{
		width: 100%;
		margin-bottom:2rem;
	}
}